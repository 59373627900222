import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide8/image/imgPortadaGuia8.png"
import img1 from "@components/pageGuide/guides/guide8/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide8/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide8/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide8/image/silder3.png"

const title = (
  <>
    <h1 sclassName="titles-underlined-ebook ebook-ventas">
      25 plantillas de chatbot para encuestas de satisfacción al cliente
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Según HubSpot, el 80% de usuarios indicaron que considerarían dejar un
      negocio por otro a partir de una sola experiencia negativa. Por eso, una
      forma de asegurarse de que comprendes la satisfacción del cliente con tu
      empresa y tus productos es mediante el uso de encuestas.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Una encuesta de servicio al cliente es más que un cuestionario. Es un método
    útil para escuchar a tus clientes, para entender si están contentos o no con
    tu marca, producto o servicios. Estas encuestas generalmente se componen de
    una selección de preguntas abiertas que fomentan respuestas simples pero
    cargadas de información.
    <br /> <br />
    Cuando se analiza los resultados, puedes utilizar estos datos para impulsar
    mejores decisiones empresariales que, en última instancia, afectan a la
    experiencia general del cliente. Por eso, en esta guía hemos junto 25 tipos
    de preguntas que puedes formular a tus clientes.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Encuestas para el proceso de compras",
      },
      {
        key: 2,
        text: "Encuestas para la experiencia del producto ",
      },
      {
        key: 3,
        text: "Encuestas para evaluar el servicio al cliente ",
      },
      {
        key: 4,
        text: "Encuestas de fidelización y postventa ",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
